@media print {
    @page {
        size: A3;
    }

    body * {
        visibility: hidden;
    }

    .dont-print {
        display: none;
    }

    .printer-only {
        display: block;
        visibility: visible;
    }

    .ant-btn {
        display: none;
    }

    header, aside {
        display: none;
    }

    #printable-content, #printable-content * {
        visibility: visible;
    }

    #printable-content {
        position: absolute;
        left: 0;
        top: 0;
    }

    div.ant-layout-has-sider > div:first-child {
        display: none;
    }

    .ant-pro-page-container-affix > div:first-child {
        display: none;
    }

    .ant-pro-card {
        min-height: 0 !important;
        page-break-inside: avoid;
    }

    .ant-pro-layout-watermark-wrapper {
        display: none;
    }

    .ant-pro-card-col {
        border-radius: 0;
        border-bottom: 1px solid lightgray;
    }

    .ant-pro-page-container-children-container {
        padding: 0;
    }

    .ant-affix {
        top: 0 !important;
        display: none;
    }

    .ant-card {
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }
}
